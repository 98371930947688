<template>
  <div class="max-w-lg p-5 m-auto">
    <article class="p-5">
      <h1
        class="mb-2 text-6xl font-bold text-transparent bg-gradient-to-r bg-clip-text from-blue-500 to-green-500"
      >
        4O4
      </h1>
      <p class="font-bold">
        La página que buscabas no existe o no tienes permiso para acceder a ella.
      </p>
      
        <router-link to="/" class="base-link">
          <button class="mt-3 bg-blue-700 hover:bg-blue-600 text-white text-lg py-3 px-4 rounded-md">
            Volver al inicio
          </button>
          </router-link>
      
    </article>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
